import Background from "ps-assets/img/bg_line.png";
import BackgroundStar from "ps-assets/img/bg_star.png";
import StartLeaf from "ps-assets/img/img01.png";
import umberlla from "ps-assets/img/umberlla-bg.png";
import nameicon from "ps-assets/img/icons.png";
import BackgroundHighlights from "ps-assets/img/famly-in-cycle-img.png";
const completeHealthInsuranceStyle = () => ({
  highlightsContent: {
    paddingLeft: `0px`,
    listStyle: `none`,
    "@media (max-width: 992px)": {
      width: `100%`,
    },
    "@media (max-width: 1200px)": {
      width: `100%`,
    },
    "@media (max-width: 320px)": {
      width: `100% !important`,
    },
  },
  highlightsContentLi: {
    fontSize: `11px`,
    color: `#848A8B`,
    width: `33.33%`,
    float: `left`,
    paddingRight: `20px`,
    paddingLeft: `20px`,
    lineHeight: `14px`,
    paddingBottom: `15px`,
    /* min-height: 50px; */
    fontWeight: `500`,
    "@media (max-width: 656px)": {
      width: `100%`,
      // minHeight: `auto`,
      clear: `both`,
      float: `none`,
    },
    "@media (max-width: 767px)": {
      paddingRight: `10px`,
      paddingLeft: `10px`,
      paddingBottom: `12px`,
    },
  },
  footerHolder: {
    width: `100%`,
    height: `auto`,
    padding: `2% 10%`,
    overflow: `hidden`,
    background: `#FFFFFF`,
    fontSize: `0.6em`,
    textAlign: `center`,
    color: `#8F8F8F`,
    lineHeight: `1.42857143`,
    fontWeight: `500`,
    "@media (max-width: 767px)": {
      lineHeight: `13px`,
    }
  },
  footer: {
    width: `100%`,
    margin: `0`,
    padding: `1% 0`,
    textAlign: `center`,
    fontSize: `11px`,
    background: `#FFFFFF`,
    color: `#8F8F8F`,
    fontWeight: `400`,
    borderTop: `2px solid #E1E4ED`,
    borderBottom: ` 2px solid #E1E4ED`,
    "@media (max-width: 767px)": {
      lineHeight: `12px`,
    }
  },
  bannerBgline: {
    "@media (max-width: 768px)": {
      minHeight: `1000px !important`,
    },
    "@media (max-width: 992px)": {
      minHeight: `750px `,
    },
    minHeight: `420px`,
    backgroundImage: `url(${Background})`,
    backgroundRepeat: `repeat-x`,
    backgroundSize: `contain`,
  },
  containerFluid: {
    paddingRight: `0px`,
    paddingLeft: `0px`,
    paddingBottom: `15px`,
    marginRight: `auto`,
    marginLeft: `auto`,
  },
  row: {
    "@media (max-width: 767px)": {
      marginRight: `0px !important`,
      marginLeft: `0px !important`,
    },
    marginRight: `-15px`,
    marginLeft: `-15px`,
  },
  starBgImage: {
    backgroundImage: `url(${BackgroundStar})`,
    minHeight: `300px`,
  },
  container: {
    paddingRight: `15px`,
    paddingLeft: `15px`,
    marginRight: `auto`,
    marginLeft: `auto`,
    paddingBottom: `5px`,
    paddingTop: `5px`,
    maxWidth: `1170px`,
    "@media (max-width: 992px)": {
      maxWidth: `970px`,
    },
    "@media (max-width: 768px)": {
      maxWidth: `750px`,
    },
  },
  fullWidthContainer: {
    "@media (max-width: 992px)": {
      width: `100%`,
    },
  },
  customWidth: {
    position: `relative`,
    minHeight: `1px`,
    paddingRight: `15px`,
    paddingLeft: `15px`,
    float: `left`,
    width: `70%`,
    // "@media (min-width: 768px)": {
    //   Width: `70%`,
    // },
    "@media (max-width: 992px)": {
      width: `100%`,
    },
    "@media (max-width: 768px)": {
      paddingRight: `10px`,
      paddingLeft: `10px`,
    },
    "@media (max-width: 760px)": {
      paddingRight: `10px`,
      paddingLeft: `13%`,
    },

    
    // "@media (min-width: 1200px)": {
    //   Width: `70%`,
    // },
  },
  customWidths: {
    position: `relative`,
    minHeight: `1px`,
    paddingRight: `15px`,
    paddingLeft: `15px`,
    float: `left`,
    width: `50%`,
    "@media (max-width: 770px)": {
      width: `100%`,
      float: `none`,
      paddingRight: `0px`,
      paddingLeft: `0px`,
    },
  },
  customWidths1: {
    position: `relative`,
    minHeight: `1px`,
    paddingRight: `15px`,
    paddingLeft: `15px`,
    float: `left`,
    width: `50%`,
    "@media (max-width: 1025px)": {
      paddingLeft: '96px',
    paddingTop: '1.9%'
    },
    
    "@media (max-width: 770px)": {
      width: `100%`,
      float: `none`,
      paddingRight: `0px`,
      paddingLeft: `0px`,
    },
  },
  innerContent: {
    "@media (max-width: 767px)": {
      padding: `0px 0px 0px !important`,
    },
    padding: `65px 0px 0px`,
    minHeight: `345px`,
  },
  bannerHints: {
    marginLeft: `auto`,
    marginRight: `auto`,
    width: `240px`,
    marginTop: `25px`,
  },
  bannerHintsPoint2: {
    marginTop: `15px`,
  },
  startLeaf: {
    content: ``,
    backgroundImage: `url(${StartLeaf})`,
    height: `55px`,
    backgroundPosition: `-15px -12px`,
    width: `29px`,
    float: `left`,
  },
  startLeafP: {
    fontSize: `11px`,
    paddingLeft: `5px`,
    paddingRight: `0px`,
    fontWeight: `500`,
    color: `#35478A`,
    maxWidth: `173px`,
    float: `left`,
    marginBottom: `0px`,
    lineHeight: `15px`
  },
  endLeaf: {
    content: ``,
    backgroundImage: `url(${StartLeaf})`,
    height: `55px`,
    backgroundPosition: `-71px -12px`,
    width: `29px`,
    float: `left`,
  },
  rowCenter: {
    "@media (max-width: 768px)": {
      paddingTop: `0px`,
    },
    paddingTop: `100px`,
    paddingBottom: `20px`,
  },
  colmdfour: {
    "@media (max-width: 768px)": {
      width: `100% !important`,
    },
    width: `33.33333333%`,
    position: `relative`,
    minHeight: `1px`,
    paddingRight: `15px`,
    paddingLeft: `15px`,
    float: `left`,
  },
  colmdfour1: {
    "@media (max-width: 768px)": {
      width: `100% !important`,
      position: `inherit`,
      minHeight: `1px`,
      paddingRight: `0px`,
      paddingLeft: `opx`,
      float: `none`,
    },
    width: `33.33333333%`,
    position: `relative`,
    minHeight: `1px`,
    paddingRight: `15px`,
    paddingLeft: `15px`,
    float: `left`,
  },
  custParaWidth: {
    maxWidth: `163px`,
    fontSize: `11px`,
    paddingLeft: `5px`,
    paddingRight: `0px`,
    fontWeight: `500`,
    color: `#35478A`,
    float: `left`,
    marginBottom: `0px`,
    "@media (max-width: 767px)": {
      lineHeight: `15px`
    }
  },
  custParaWidth01: {
    maxWidth: `155px`
  },
  paddingRight: {
    paddingRight: `0px`,
  },
  divUmberlla: {
    "@media (max-width: 770px)": {
      marginTop: `30px !important`,
      position: `none !important`,
      textAlign: `center`,
    },
    "@media (max-width: 760px)": {
      marginTop: `89px !important`,
      position: `none !important`,
      textAlign: `center`,
    },
    position: `relative`,
    zIndex: `1000`,
  },
  umberllaBg: {
    "@media (max-width: 768px)": {
      marginTop: `-99px !important`,
      position: `none !important`,
      // marginTop: `-50px`,
      // position: `relative`,
      marginLeft: `auto`,
      marginRight: `auto`,
      width: `fit-content`,
      backgroundPosition: "5%   ",
      "& img":{
        width: `auto`
      }
    },
    backgroundImage: `url(${umberlla})`,
    backgroundRepeat: "no-repeat",

    height: `99px`,
    width: `140px`,
    zIndex: `-1`,
    marginLeft: `auto`,
    marginRight: `auto`,
    marginTop: `-75px`,
    position: `absolute`,
    left: `25%`,
  },
  umberllaParagraph: {
    zIndex: `1000`,
    fontSize: `16px`,
    fontWeight: `400`,
    color: `#1C3785`,
    textAlign: `center`,
    lineHeight: `16px`,
    marginTop:'2%'
  },
  boldText: {
    fontWeight: `500`,
    lineHeight: `27px`,
    textAlign: `center`,
    fontSize: `24px`,
  },
  mdTweleve: {
    // "@media (min-width: 768px)": {
    //   maxWidth: `540px`,
    // },
    "@media (min-width: 768px)": {
      maxWidth: `48%`,
    },
    "@media (min-width: 992px)": {
      maxWidth: `960px`,
    },
    "@media (min-width: 1200px)": {
      maxWidth: `1440px`,
    },

    width: `100%`,
    position: `relative`,
    minHeight: `1px`,
    paddingRight: `15px`,
    paddingLeft: `15px`,
    float: `left`,
  },
  lastbanner: {
    "@media (max-width: 768px)": {
      marginTop: `0px !important`,
      width: `270px !important`,
      marginLeft: `4%`,
      marginRight: `0px`,

      // textAlign:`center`
    },

    marginLeft: `auto`,
    marginRight: `auto`,
    width: `270px`,
    marginTop: `20px`,
  },
  customWidthtwo: {
    width: `30%`,
    "@media (max-width: 992px)": {
      width: `100% !important`,
    },
    "@media (max-width: 768px)": {
      marginTop: `13% !important`,
      width: `92% !important`,
      marginLeft: `4%`
    },
    position: `relative`,
    minHeight: `1px`,
    paddingRight: `0px !important`,
    paddingLeft: `0px !important`,
    // "@media (min-width: 1200px)": {
    //   maxWidth: `1440px`,
    // },
  },
  wrapperForm: {
    height: `fit-content`,
    backgroundColor: `#ADE8FF`,
    padding: `14px 20px`,
    marginTop: `-25px`,
    marginLeft: `auto`,
    fontSize: `14px`,
    "& p": {
      color: `black !important`,
      fontWeight: `400 !important`,
    },
    "@media (max-width: 768px)": {
      "& p": {
        lineHeight: `22px`,
        marginBottom: `0px`
      }
    }
  },
  formBox: {
    padding: `3px 10px 3px`,
    border: `1px solid #DCDFE0`,
    backgroundColor: `#fff`,
    marginBottom: `8px`,
    marginLeft: "2%",
    "@media (max-width: 768px)": {
      marginLeft: "0%",
      marginBottom: `6px`,
    }
  },
  nameIcons: {
    backgroundImage: `url(${nameicon})`,
    width: `16px`,
    height: `16px`,
    backgroundPosition: `-79px -35px`,
    position: `absolute`,
    right: `8px`,
    zIndex: `1`,
    top: `6px`,
  },
  formControls: {
    width: `100%`,
    borderRadius: `0px`,
    border: `none`,
    height: `24px`,
    padding: `3px 0px`,
    fontSize: `13px`,
    webkitBoxShadow: `none`,
    boxShadow: `none`,
    webkitTransition: `none`,
    oTransition: `noen`,
    transition: `none`,
    color: `#666666`,
    webkitAppearance: `none`,
    mozAppearance: `none`,
  },
  labels: {
    width: `100%`,
    fontSize: `13px`,
    color: `#3A4A85`,
    fontWeight: `500`,
    marginBottom: `0px`,
    position: `relative`,
  },
  emailIcons: {
    backgroundImage: `url(${nameicon})`,
    width: `16px`,
    height: `16px`,
    backgroundPosition: `-81px -92px`,
    position: `absolute`,
    right: `8px`,
    zIndex: `1`,
    top: `6px`,
  },
  mobileIcons: {
    backgroundImage: `url(${nameicon})`,
    width: `16px`,
    height: `23px`,
    backgroundPosition: `-79px -150px`,
    position: `absolute`,
    right: `8px`,
    zIndex: `1`,
    top: `6px`,
  },
  cityIcons: {
    backgroundImage: `url(${nameicon})`,
    width: `16px`,
    height: `19px`,
    backgroundPosition: `-79px -211px`,
    position: `absolute`,
    right: `8px`,
    zIndex: `1`,
    top: `6px`,
  },
  mgtLg: {
    marginBottom: `16px`,
  },
  pullRight: {
    "@media (max-width: 768px)": {
      float: `none !important`,
    },
    float: `right!important`,
    // display: "none",
  },
  submitButton: {
    fontSize: `14px`,
    color: `#344985 !important`,
    borderRadius: `18px !important`,
    border: `2px solid #344985 !important`,
    fontWeight: `500`,
    backgroundColor: `#ffffff !important`,
    padding: `6px 24px`,
    paddingBottom: `6px`,
    cursor: `pointer`,
  },
  select: {
    webkitAppearance: `none !important`,
    mozAppearance: `none !important`,
    textIndent: `1px`,
    textOverflow: ``,
  },
  pullLeft: {
    float: `left!important`,
  },
  starLogo: {
    maxWidth: `none`,
    "@media (max-width: 768px)": {
      paddingLeft: `15px`
    }
  },
  headerBg: {
    backgroundColor: `#35478A`,
    minWidth: `85px`,
  },
  rowHeader: {
    "@media (max-width: 992px)": {
      maxWidth: `100%`,
      paddingLeft: `15px`,
      paddingRight: `15px`,
    },
    width: `880px`,
    margin: `auto`,
  },
  rowHeaderh2: {
    "@media (max-width: 992px)": {
      textAlign: `center`,
    },
    "@media (max-width: 767px)": {
      fontSize: `18px`,
      paddingLeft: `10px`,
      paddingRight: `10px`,
      lineHeight: `20px`
    },
    color: `#fff`,
    fontSize: `27px`,
    marginBottom: `0px`,
    marginTop: `0px`,
    paddingTop: `10px`,
    fontWeight: `500`,
  },
  headerWrapperP: {
    "@media (max-width: 992px)": {
      textAlign: `center`,
      width: `100%`,
    },
    "@media (max-width: 767px)": {
      fontSize: `13px`,
      paddingLeft: `10px`,
      paddingRight: `10px`,
      lineHeight: `17px`,
      paddingBottom: `12px`
    },
    width: `595px`,
    textAlign: `center`,
    fontSize: `15px`,
    color: `#B1E7FF`,
    fontWeight: `500`,
  },
  headerWrapper: {
    color: `#fff`,
    fontSize: `27px`,
    marginBottom: `0px`,
    marginTop: `0px`,
    paddingTop: `20px`,
  },
  paragraphWrapper: {
    width: `595px`,
    textAlign: `center`,
    fontSize: `15px`,
    color: `#B1E7FF`,
    fontWeight: `500`,
  },
  textCenter: {
    textAlign: `center`,
  },
  highlightsBg: {
    backgroundColor: `#E2F5FA`,
    minHeight: `230px`,
    marginTop: "4%",
    marginBottom: "4%",
    "@media (max-width: 768px)": {
      marginTop: "4%",
      marginBottom: "0%",
      paddingBottom: `0px`
    }
  },
  highlightsBackground: {
    "@media (max-width: 768px)": {
      backgroundImage: `none`,
    },
    "@media only screen and (max-width: 992px)": {
      marginTop: `-33px`,
      height: `auto`,
    },
    backgroundImage: `url(${BackgroundHighlights})`,
    height: `247px`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `left bottom`,
    zIndex: `1000`,
    marginTop: `-18px`,
  },
  rowflex: {
    "@media (max-width: 768px)": {
      flexDirection: `column`,
    },
    "@media (max-width:  992px)": {
      marginTop: "-150px",
      minWidth: "100% !important",
    },
    display: `inline-flex`,
    marginBottom: `25px`,
    // marginRight: `-15px`,
    // marginLeft: `-15px`,
  },
  boxesstyle: {
    backgroundColor: "#0040a5",
    paddingTop: "15px",
    paddingBottom: "20px",
    paddingLeft: "50px",
    paddingRight: "50px",
    width: "30%",
    // height: "",
    "@media (max-width:  768px)": {
      minWidth: "91.5% !important",
      paddingLeft: "19px",
      paddingRight: "19px",
      paddingBottom: `5px`,
      marginLeft: `auto`,
      marginRight: `auto`
    },
    "& div": {
      "& h4": {
        lineHeight: `15px`
      },
      "& p": {
        lineHeight: `14px`,
        fontSize: `12px`
      }
    }
  },
  containerStyle: {
    "@media (max-width: 768px)": {
      width: `100%`,
      marginTop: `0 px`,
      marginLeft: "0px",
    },
    // paddingRight: `5%`,
    // paddingLeft: `5%`,
    marginLeft: "4.5%",
    // marginRight: `auto`,
    // marginLeft: `auto`,
    marginTop: `20px`,
    // display: "none",
    width: `100%`,
    // "@media (max-width: 768px)": {

    // },
  },
  displayFlexRow: {
    "@media (max-width: 767px)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
    displayFlexTwo: {
      "@media (max-width: 768px)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      },
    },
  submitButtonDiv: {
    width: `fit-content`,
    marginLeft: `auto`,
    marginTop: `15px`,
    "@media (max-width: 768px)": {
      marginTop: `7px !important`,
      marginBottom: `43px`
    },
    "@media (max-width: 500px)": {
      marginTop: `7px !important`,
      marginBottom: `0px`,
      width: `auto`,
      textAlign:'center'
    }
  },
  buttondiv: {
    marginBottom: "5%",
    position: "inherit",
    "@media (max-width: 768px)": {
      marginBottom: "10%",
    },
    "@media (max-width: 468px)": {
      marginBottom: "15% !important",
    },
  },
  yourLoved: {
    fontSize: "21px",
    color: "#35478A",
    marginBottom: "0px",
    paddingBottom: "18px",
    marginTop: "0px",
    paddingTop: "40px",
    "@media (max-width: 768px)": {
      fontSize: "20px",
      fontWeight: '500',
      paddingBottom: `12px`
    }
  },
});
export default completeHealthInsuranceStyle;
